export default {
  HOME: "/",
  LOGIN: "/login",
  SECOND_FACTOR_LOGIN: "/second-factor-login",
  SECOND_FACTOR_RESET: "/reset-auth/token_reset/:token",
  LOGIN_WITH_TOKEN: "/login/:token",
  LOGOUT: "/logout",
  PASSWORD_RESET: "/password-reset",
  PASSWORD_RESET_CONFIRM: "/reset-auth/reset-password/:token",
  CREATE_ACCOUNT: "/create-account",
  USERS_LIST: "/users",
  ADD: "/add-many-users",
  ADD_USER: "/add-user",
  ADD_USERS: "/add-many-users/:usersRole",
  PRICE_TARIFFS: "/price-tariffs",
  USER_PROFILE: "/user/profile/:username/:tabName?",
  USER_RATINGS: "/user/profile/:userId/ratings",
  USER_TARIFFS: "/user/profile/:userId/tariffs",
  USER_NOTIFICATIONS: "/user/notifications",
  USER_NOTIFICATIONS_NEW_TAB: "/user/notifications/:subTab",
  TASKS_LIST: "/tasks",
  RATING: "/ratings/:ratingId/",
  TASK_RATINGS: "/ratings/task/:taskSlug",
  NEW_RATING: "/ratings/new/:username/:taskSlug",
  NEW_RATING_NO_SLUG: "/ratings/new/:username/",
  TASK: "/task/:taskSlug",
  TASK_TAB: "/task/:taskSlug/:subTab",
  TASKS_STATISTICS: "/tasks-statistics",
  TASK_STATISTICS: "/task-statistics/:taskSlug",
  BASIC_TASK_STATISTICS: "/basic-task-statistics/:taskSlug",
  BASIC_ADMIN_FAST_USERS: "/admin/task/:taskSlug/users",
  GROUP_TASK_STATISTICS: "/task-statistics-group",
  USERS_STATISTICS: "/users-statistics",
  USER_STATISTICS: "/user-statistics/:userName",
  TASK_USER_STATISTICS: "/task-statistics/task-user/:taskSlug/:userName",
  REPORTS: "/reports",
  USER_REPORT: "/reports/linguist-report",
  REPORTS_LIST: "/reports/linguists-report-list",
  REPORTS_DEADLINE: "/reports/linguists-report-deadline",
  UPEU: "/upeu",
  PASSWORD_CHANGE: "/password-change",
  COMPANIES: "/companies",
  COMPANY_DETAIL: "/company/:id",
  COMPANY_DETAIL_NEW: "/company/new",
  USERS_PERMISSIONS: "/users-permissions",
  USERS_PERMISSIONS_DETAIL: "/users-permissions/:id",
  DIVISIONS: "/divisions",
  DIVISION_DETAIL: "/division/:id",
  DIVISION_DETAIL_NEW: "/division/new",
  MODULES: "/modules",
  MODULE_DETAIL: "/module/:id",
  MODULE_DETAIL_NEW: "/module/new",
  SUPPORT: "/support",
  SUPPORT_TICKETS: "/support/tickets",
  SUPPORT_TICKET_NEW: "/support/ticket/new",
  SUPPORT_TICKET_DETAIL: "/support/ticket/:id",

  // admin
  ADMIN_TASK_LIST: "/admin/tasks",
  ADMIN_TASK_DETAIL: "/admin/task/:taskSlug",
  ADMIN_TASK_DETAIL_TAB: "/admin/task/:taskSlug/:subTab",
  ADMIN_TASK_DETAIL_NEW: "/admin/new/task",
  ADMIN_TASK_DETAIL_NEW_TAB: "/admin/new/task/:subTab",
  ADMIN_QUESTIONS_BY_TASK_LIST: "/admin/:taskSlug/questions",
  ADMIN_QUESTIONS_DETAIL: "/admin/question-detail/:id",
  ADMIN_ANSWERS_BY_TASK_LIST: "/admin/:taskSlug/answers",
  ADMIN_ANSWER_DETAIL: "/admin/answer-detail/:id",
  ADMIN_GENERATE_REPORTS: "/admin/generate-reports",

  // connected with django
  WIDGETS_EDIT: "/widgets-edit",
  WIDGETS_TESTS: "/widgets-tests",
  DOCUMENTATION_WIDGETS: "/docs",

  AUDIO_HUNTER_CONFIRM: "/audio-hunter-confirm/:token",
  AUDIO_HUNTER_MIGRATE: "/audio-hunter-migrate",
  AUDIO_HUNTER_COMPANY_MIGRATE: "/audio-hunter-company-migrate",
};
