import _ from "lodash";
import React, { Component } from "react";
import {
  Grid,
  Modal,
  Icon,
  Checkbox,
  Button,
  Input,
  Popup,
  List,
  Accordion,
  Dropdown,
} from "semantic-ui-react";
import fs from "file-saver";
import { Buffer } from "buffer";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import "react-dates/initialize";
import PropTypes from "prop-types";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import { GROUPED_BY } from "./utils";

import "../Tasks.css";
import "react-dates/lib/css/_datepicker.css";

const forbidden_columns = [
  "first_activity",
  "last_activity",
  "gross_cost_company",
  "commission",
  "rate_net_for_linguist",
  "net_for_linguist",
];

export class ReportExport extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      is_company_coordinator: PropTypes.bool,
    }).isRequired,
    groupedBy: PropTypes.string,
    reports: PropTypes.array,
    closeModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.exportReports = this.exportReports.bind(this);
    this.state = {
      badges: [],
      searched_username: [],
      open_modal_window_badge: false,
      columns_selected: [],
      template: "",
      button_disabled: false,
      columnsToExport: [],
      active: false,
    };
  }

  template_choices = [
    { key: "", value: "", text: "" },
    { key: "ELE", value: "ELE", text: "ELE" },
    { key: "EC", value: "EC", text: "External Company" },
  ];

  internalPreset() {
    let columns_selected = [
      "project",
      "module",
      "task",
      "full_name",
      "login",
      "nativity",
      "company",
      "hours_project",
      "rate_net_for_linguist",
      "net_for_linguist",
      "gross_cost_company",
      "comments",
      "commission",
      "total_cost",
      "first_activity",
      "last_activity",
    ];
    if (this.props.auth.is_company_coordinator) {
      columns_selected = columns_selected.filter(
        (column) => !forbidden_columns.includes(column)
      );
    }
    this.setState(
      {
        columns_selected,
      },
      this.displayColumns
    );
  }

  externalPreset() {
    let columns_selected = [
      "project",
      "task",
      "full_name",
      "company",
      "hours_project",
      "rate_net_for_linguist",
      "net_for_linguist",
      "gross_cost_company",
      "comments",
      "commission",
      "total_cost",
    ];
    if (this.props.auth.is_company_coordinator) {
      columns_selected = columns_selected.filter(
        (column) => !forbidden_columns.includes(column)
      );
    }
    this.setState(
      {
        columns_selected,
      },
      this.displayColumns
    );
  }

  allPreset() {
    let columns_selected = [
      "project",
      "module",
      "task",
      "full_name",
      "login",
      "nativity",
      "company",
      "hours_project",
      "rate_net_for_linguist",
      "net_for_linguist",
      "gross_cost_company",
      "comments",
      "commission",
      "total_cost",
      "first_activity",
      "last_activity",
    ];
    if (this.props.auth.is_company_coordinator) {
      columns_selected = columns_selected.filter(
        (column) => !forbidden_columns.includes(column)
      );
    }
    this.setState(
      {
        columns_selected,
      },
      this.displayColumns
    );
  }

  nonePreset() {
    const columns_selected = [];
    this.setState({
      columns_selected,
      columnsToExport: [],
    });
  }

  exportReports() {
    this.setState({ button_disabled: true });
    let report_ids = [];
    const reports = this.props.reports;

    switch (this.props.groupedBy) {
      case GROUPED_BY.USER:
        for (let i = 0; i < reports.length; i++) {
          for (let j = 0; j < reports[i].tasks_reports.length; j++) {
            report_ids.push(reports[i].tasks_reports[j]["id"]);
          }
        }
        break;
      case GROUPED_BY.TASK_AND_PROJECT:
        for (let i = 0; i < reports.length; i++) {
          for (let j = 0; j < reports[i].reports.length; j++) {
            for (let x = 0; x < reports[i].reports[j].reports.length; x++) {
              report_ids.push(reports[i].reports[j].reports[x]["id"]);
            }
          }
        }
        break;
      default:
        for (let i = 0; i < reports.length; i++) {
          for (let j = 0; j < reports[i].reports.length; j++) {
            report_ids.push(reports[i].reports[j]["id"]);
          }
        }
        break;
    }

    const redundantColumns = [
      "description",
      "hours task",
      "currency",
      "tariff ID",
    ];
    let columns_selected = this.state.columns_selected.filter(
      (item) => !redundantColumns.includes(item)
    );

    if (this.state.template !== "") {
      columns_selected = [];
    }

    ajax
      .post(config.LINGUIST_EXPORT_REPORTS, {
        data: {
          report_ids: report_ids,
          columns: columns_selected,
          template: this.state.template,
          filename_fragment: this.state.filenameFragment,
        },
        responseType: "arraybuffer",
      })
      .then((result) => {
        const blob = new Blob([result.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        fs.saveAs(blob, result.headers["x-filename"]);
        this.props.closeModal();
      })
      .catch((error) => {
        const buffer = Buffer.from(error.response.data);
        try {
          const errorData = JSON.parse(buffer.toString());
          const errorMessage = errorData.details || "Failed to generate report";
          toastr.error(errorMessage);
        } catch (e) {
          toastr.error("Failed to generate report");
        }
      })
      .finally(() => {
        this.setState({ button_disabled: false });
      });
  }

  displayColumns = () => {
    let columnsToExport = this.state.columns_selected;
    let template = this.state.template;

    if (template !== "") {
      this.setState({ columns_selected: [] });
    } else {
      const task = [
        "description",
        "language",
        "hours task",
        "answers",
        "evaluations",
        "reviews",
        "evaluation reviews",
      ];
      const rateNetForLinguist = ["currency", "tariff ID"];
      const netForLinguist = ["tariff ID"];

      _.map(columnsToExport, (item) => {
        if (item === "task") {
          columnsToExport = columnsToExport.concat(task);
        }
        if (item === "rate_net_for_linguist") {
          columnsToExport = columnsToExport.concat(rateNetForLinguist);
        }
        if (item === "net_for_linguist") {
          columnsToExport = columnsToExport.concat(netForLinguist);
        }
      });
      columnsToExport = columnsToExport.filter(
        (item, index) => columnsToExport.indexOf(item) === index
      );
    }
    this.setState({ columnsToExport });
  };

  toggleCheckboxToUpdate = (event, input) => {
    let columns_selected = this.state.columns_selected;
    let template = this.state.template;
    if (!template) {
      if (input.checked === true) {
        if (_.includes(columns_selected, input.value)) {
          return;
        }
        columns_selected.push.apply(columns_selected, [input.value]);
      } else {
        if (_.includes(columns_selected, input.value)) {
          columns_selected = _.pull(columns_selected, input.value);
        }
      }
      this.setState({ columns_selected }, this.displayColumns);
    }
  };

  render() {
    return (
      <Modal
        dimmer={true}
        open={true}
        onClose={this.props.closeModal}
        closeonrootnodeclick={"true"}
        className={"ReportExport"}
      >
        <Modal.Header>
          <Icon name={"external"} />
          Export Reports
        </Modal.Header>
        <Modal.Content>
          <Grid columns={4}>
            <Grid.Row centered>
              <Grid.Column className={"ui center aligned"}>
                <Button
                  className={"datahive-dark-grey-button"}
                  onClick={() => {
                    this.internalPreset();
                  }}
                >
                  Internal Preset
                </Button>
              </Grid.Column>
              <Grid.Column className={"ui center aligned"}>
                <Button
                  className={"datahive-dark-grey-button"}
                  onClick={() => {
                    this.externalPreset();
                  }}
                >
                  External Preset
                </Button>
              </Grid.Column>
              <Grid.Column className={"ui center aligned"}>
                <Button
                  className={"datahive-dark-grey-button"}
                  onClick={() => {
                    this.nonePreset();
                  }}
                >
                  Select none
                </Button>
              </Grid.Column>
              <Grid.Column className={"ui center aligned"}>
                <Button
                  className={"datahive-dark-grey-button"}
                  onClick={() => {
                    this.allPreset();
                  }}
                >
                  Select all
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={3}>
              <Grid.Column className={"ui center aligned"}>
                <Input
                  placeholder="Filename"
                  value={this.state.filenameFragment || ""}
                  onChange={(e) =>
                    this.setState({ filenameFragment: e.target.value })
                  }
                />
              </Grid.Column>
              <Grid.Column className={"ui center aligned"}>
                <Dropdown
                  placeholder="Specification template"
                  selection
                  options={this.template_choices}
                  value={this.state.template}
                  onChange={(e, { value }) =>
                    this.setState({ template: value })
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            columns={5}
            style={{
              ...(this.state.template && {
                opacity: 0.5,
                pointerEvents: "none",
              }),
            }}
          >
            <Grid.Row centered>
              <Grid.Column>
                <Checkbox
                  value={"project"}
                  label="Project"
                  checked={
                    _.indexOf(this.state.columns_selected, "project") >= 0
                  }
                  onClick={this.toggleCheckboxToUpdate}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  value={"module"}
                  label="Module"
                  checked={
                    _.indexOf(this.state.columns_selected, "module") >= 0
                  }
                  onClick={this.toggleCheckboxToUpdate}
                />
              </Grid.Column>
              <Grid.Column>
                <Popup
                  trigger={
                    <Checkbox
                      value={"task"}
                      label="Task"
                      checked={
                        _.indexOf(this.state.columns_selected, "task") >= 0
                      }
                      onClick={this.toggleCheckboxToUpdate}
                    />
                  }
                  content={
                    "Columns: Task, Description, Language, Hours task, Answers, Evaluations, Reviews, Evaluation reviews"
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  value={"full_name"}
                  label="Full name"
                  checked={
                    _.indexOf(this.state.columns_selected, "full_name") >= 0
                  }
                  onClick={this.toggleCheckboxToUpdate}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column>
                <Checkbox
                  value={"login"}
                  label="Login"
                  checked={_.indexOf(this.state.columns_selected, "login") >= 0}
                  onClick={this.toggleCheckboxToUpdate}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  value={"nativity"}
                  label="Nativity"
                  checked={
                    _.indexOf(this.state.columns_selected, "nativity") >= 0
                  }
                  onClick={this.toggleCheckboxToUpdate}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  value={"company"}
                  label="Company"
                  checked={
                    _.indexOf(this.state.columns_selected, "company") >= 0
                  }
                  onClick={this.toggleCheckboxToUpdate}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  value={"hours_project"}
                  label="Hours Project"
                  checked={
                    _.indexOf(this.state.columns_selected, "hours_project") >= 0
                  }
                  onClick={this.toggleCheckboxToUpdate}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column>
                <Checkbox
                  value={"comments"}
                  label="Comments"
                  checked={
                    _.indexOf(this.state.columns_selected, "comments") >= 0
                  }
                  onClick={this.toggleCheckboxToUpdate}
                />
              </Grid.Column>
              {!this.props.auth.is_company_coordinator && (
                <>
                  <Grid.Column>
                    <Checkbox
                      value={"first_activity"}
                      label="First Activity"
                      checked={
                        _.indexOf(
                          this.state.columns_selected,
                          "first_activity"
                        ) >= 0
                      }
                      onClick={this.toggleCheckboxToUpdate}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      value={"last_activity"}
                      label="Last Activity"
                      checked={
                        _.indexOf(
                          this.state.columns_selected,
                          "last_activity"
                        ) >= 0
                      }
                      onClick={this.toggleCheckboxToUpdate}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Popup
                      trigger={
                        <Checkbox
                          value={"rate_net_for_linguist"}
                          label="Rate net for Linguist"
                          checked={
                            _.indexOf(
                              this.state.columns_selected,
                              "rate_net_for_linguist"
                            ) >= 0
                          }
                          onClick={this.toggleCheckboxToUpdate}
                        />
                      }
                      content={
                        "Columns: Rate net for Linguist, Currency, Tariff ID"
                      }
                    />
                  </Grid.Column>
                </>
              )}
            </Grid.Row>
            <Grid.Row centered>
              {!this.props.auth.is_company_coordinator && (
                <>
                  <Grid.Column>
                    <Popup
                      trigger={
                        <Checkbox
                          value={"net_for_linguist"}
                          label="Net for Linguist"
                          checked={
                            _.indexOf(
                              this.state.columns_selected,
                              "net_for_linguist"
                            ) >= 0
                          }
                          onClick={this.toggleCheckboxToUpdate}
                        />
                      }
                      content={"Columns: Net for Linguist, Tariff ID"}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      value={"gross_cost_company"}
                      label="Gross Cost Company"
                      checked={
                        _.indexOf(
                          this.state.columns_selected,
                          "gross_cost_company"
                        ) >= 0
                      }
                      onClick={this.toggleCheckboxToUpdate}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      value={"commission"}
                      label="Commission"
                      checked={
                        _.indexOf(this.state.columns_selected, "commission") >=
                        0
                      }
                      onClick={this.toggleCheckboxToUpdate}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      value={"total_cost"}
                      label="Total Cost"
                      checked={
                        _.indexOf(this.state.columns_selected, "total_cost") >=
                        0
                      }
                      onClick={this.toggleCheckboxToUpdate}
                    />
                  </Grid.Column>
                </>
              )}
            </Grid.Row>
          </Grid>
          <Grid
            style={{
              ...(this.state.template && {
                opacity: 0.5,
                pointerEvents: "none",
              }),
            }}
          >
            <Grid.Row centered textAlign="center">
              <Accordion>
                <Accordion.Title
                  active={this.state.active}
                  onClick={() => this.setState({ active: !this.state.active })}
                >
                  <Icon size="small" name="dropdown" />
                  Selected columns:
                </Accordion.Title>
                <Accordion.Content active={this.state.active}>
                  <List celled horizontal>
                    {this.state.columnsToExport.map((item) => (
                      <List.Item key={"column" + item}>{item}</List.Item>
                    ))}
                  </List>
                </Accordion.Content>
              </Accordion>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions className={"centered"}>
          <Button
            disabled={this.state.button_disabled}
            className={"datahive-yellow-button"}
            onClick={() => {
              this.exportReports();
            }}
          >
            Export
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(ReportExport);
