import _ from "lodash";
import React, { Component } from "react";
import moment from "moment";
import autosize from "autosize";
import PropTypes from "prop-types";
import {
  Form,
  Dropdown,
  Segment,
  Button,
  Icon,
  Image,
} from "semantic-ui-react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import store from "../../../store/store";
import { withRouter } from "react-router-dom";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import ContainerDetail from "../../../components/ContainerDetail";
import {
  formatErrorMessage,
  getPossibleDropdownValues,
} from "../../../helpers/utils";
import "./TicketDetail.css";

class TicketDetail extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
    this.BASE_MEDIA_URL = config.BACKEND_URL + config.MEDIA_SUFFIX;

    this.emptyTicket = {
      id: null,
      author: null,
      title: "",
      description: "",
      category: "",
      status: "",
      priority: null,
      attachments: [],
      created_at: "",
      last_updated: "",
    };
    this.state = {
      displaySpinner: true,
      categories: [],
      statuses: [],
      priorities: [],
      attachments: [],
      ticket: { ...this.emptyTicket },
      isEditable: false,
      displayDeleteModal: false,
    };
  }

  componentDidMount() {
    autosize(this.textareaRef.current);
    this.getDropdownOptions();
    this.getData();
  }

  componentDidUpdate() {
    autosize.update(this.textareaRef.current);
  }

  getDropdownOptions = () => {
    ajax.get(config.TICKET_OPTIONS).then((response) => {
      const categoriesData = response.data.categories;
      const statusesData = response.data.statuses;
      const prioritiesData = response.data.priorities;

      const categories = getPossibleDropdownValues(
        "value",
        "name",
        categoriesData,
        true,
        false
      );

      const statuses = getPossibleDropdownValues(
        "value",
        "name",
        statusesData,
        true,
        false
      );

      const priorities = getPossibleDropdownValues(
        "value",
        "name",
        prioritiesData,
        true,
        false
      );

      this.setState({ categories, statuses, priorities });
    });
  };

  getData = () => {
    if (!this.props.location.pathname.includes(urls.SUPPORT_TICKET_NEW)) {
      ajax
        .get([config.TICKET_DETAIL, this.props.match.params.id])
        .then((response) => {
          this.setState({
            ticket: response.data,
            displaySpinner: false,
          });
        })
        .catch((error) => {
          toastr.error("Error", formatErrorMessage(error.response.data));
          this.props.history.push(urls.SUPPORT);
        });
    } else {
      this.setState({
        displaySpinner: false,
      });
    }
  };

  update = () => {
    let data = _.cloneDeep(this.state.ticket);
    if (!this.props.location.pathname.includes(urls.SUPPORT_TICKET_NEW)) {
      ajax
        .patch([config.TICKET_DETAIL, data.id], { data })
        .then(() => {
          toastr.success("Success", "Ticket updated!");
          if (this.state.isEditable) {
            this.toggleEditable();
          }
        })
        .catch((error) => {
          let message = "Error ";
          if (error.response) {
            for (const key in error.response.data) {
              message = message + key + ": " + error.response.data[key] + " ";
            }
          }
          toastr.error("Error!", message);
        });
    }
  };

  delete = () => {
    let data = _.cloneDeep(this.state.ticket);
    if (!this.props.location.pathname.includes(urls.SUPPORT_TICKET_NEW)) {
      ajax
        .delete([config.TICKET_DETAIL, data.id], { data })
        .then(() => {
          this.props.history.push(urls.SUPPORT);
          toastr.success("Success", "Ticket deleted!");
        })
        .catch((error) => {
          toastr.error("Error!", error.response);
        });
    } else {
      toastr.error("Error!", "Ticket doesn't exist");
    }
  };

  changeTicket = (newVal) =>
    this.updateTicket({
      ...this.state.ticket,
      ...newVal,
    });

  updateTicket = (ticket) => {
    this.setState({ ticket });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      displayDeleteModal: !prevState.displayDeleteModal,
    }));
  };

  toggleEditable = () => {
    this.setState((prevState) => ({
      isEditable: !prevState.isEditable,
    }));
  };

  renderAttachmentPreview = (attachment) => {
    const imageValidExtensions = ["jpeg", "jpg", "png"];
    const { url, name, extension } = attachment;
    if (url && imageValidExtensions.includes(extension?.toLowerCase())) {
      return <Image src={this.BASE_MEDIA_URL + url} alt={name} />;
    } else {
      return <Icon name="file" size="big" />;
    }
  };

  render() {
    const {
      ticket,
      displaySpinner,
      categories,
      statuses,
      priorities,
      isEditable,
      displayDeleteModal,
    } = this.state;
    const { history } = this.props;
    const { is_superuser } = store.getState().auth;
    const hasEditAccess = is_superuser;
    const ticketName = "#" + ticket.id + " " + ticket.title;
    const extTicket = { name: ticketName, ...ticket };

    const locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);

    const styleInactive = {
      backgroundColor: "#f7f7f7",
      pointerEvents: "none",
    };

    const details = (
      <>
        {hasEditAccess && (
          <div className="lock">
            <Icon
              name={isEditable ? "unlock" : "lock"}
              size="large"
              onClick={this.toggleEditable}
            />
          </div>
        )}
        <Segment
          className="datahive-segment"
          style={!isEditable ? styleInactive : null}
        >
          <Form className="ticket-detail-form">
            <Form.Group widths="equal">
              <Form.Field>
                <label>Category</label>
                {categories ? (
                  <Dropdown
                    className="category"
                    placeholder="Category"
                    fluid
                    search
                    selection
                    value={ticket.category}
                    options={categories}
                    onChange={(e, { value }) =>
                      this.changeTicket({ category: value })
                    }
                  />
                ) : (
                  <Dropdown disabled loading />
                )}
              </Form.Field>

              <Form.Field>
                <label>Status</label>
                {statuses ? (
                  <Dropdown
                    className="status"
                    placeholder="Status"
                    fluid
                    search
                    selection
                    value={ticket.status}
                    options={statuses}
                    onChange={(e, { value }) =>
                      this.changeTicket({ status: value })
                    }
                  />
                ) : (
                  <Dropdown disabled loading />
                )}
              </Form.Field>
              <Form.Field>
                <label>Priority</label>
                {priorities ? (
                  <Dropdown
                    className="priority"
                    placeholder="Priority"
                    fluid
                    search
                    selection
                    value={ticket.priority}
                    options={priorities}
                    onChange={(e, { value }) =>
                      this.changeTicket({ priority: value })
                    }
                  />
                ) : (
                  <Dropdown disabled loading />
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label className="user-info-field data-field-left">
                  Date created:
                </label>
                <span className="user-info-field">
                  {ticket.created_at
                    ? moment(ticket.created_at).format(config.FORMAT_Ymd)
                    : ""}
                </span>
              </Form.Field>
              <Form.Field>
                <label className="user-info-field data-field-left">
                  Last update:
                </label>
                <span className="user-info-field">
                  {ticket.last_updated
                    ? moment(ticket.last_updated).format(config.FORMAT_Ymd)
                    : ""}
                </span>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Description:</label>
                <textarea
                  fluid="true"
                  readOnly={!isEditable}
                  placeholder="Description"
                  value={ticket.description}
                  style={{ minHeight: "100px", pointerEvents: "all" }}
                  ref={this.textareaRef}
                  onChange={(e) =>
                    this.changeTicket({ description: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal" className="style-active">
              <Form.Field>
                <label>Attachments</label>
                <ul className="attachments">
                  {ticket.attachments?.map((attachment) => (
                    <li
                      key={attachment.name}
                      className="attachment"
                      onClick={() =>
                        window.open(
                          this.BASE_MEDIA_URL + attachment.url,
                          "_blank"
                        )
                      }
                    >
                      <div className="attachment-preview">
                        {this.renderAttachmentPreview(attachment)}
                        <p>{attachment.name}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </>
    );
    const panelRight = isEditable ? (
      <Button
        color="green"
        className="datahive-button"
        onClick={this.update}
        content="Update"
      />
    ) : null;
    const panelLeft = (
      <>
        {isEditable && (
          <Button
            color="red"
            className="datahive-button"
            onClick={this.toggleModal}
            content="Delete"
          />
        )}
      </>
    );
    const title = "Are you sure you want to delete this ticket?";
    const buttons = (
      <>
        <Button
          onClick={this.delete}
          content="Delete"
          color="red"
          className="datahive-button"
          id={"delete"}
          floated={"left"}
        />
        <Button
          onClick={this.toggleModal}
          className="datahive-button"
          color="grey"
          content="Cancel"
        />
      </>
    );

    return (
      <ContainerDetail
        details={details}
        url={urls.SUPPORT_TICKET_DETAIL}
        urlNew={urls.SUPPORT_TICKET_NEW}
        button={buttons}
        item={extTicket}
        history={history}
        displaySpinner={displaySpinner}
        displayDeleteModal={displayDeleteModal}
        panelRight={panelRight}
        panelLeft={panelLeft}
        title={title}
        toggleModal={this.toggleModal}
      />
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default withRouter(connect(mapStateToProps)(TicketDetail));
