/* eslint-disable no-undef */
/* Process is empty if you serve this from nginx via static page.
 * Check process and if is empty, check window for our variables (dwork)
 * */
import moment from "moment";

const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL !== undefined &&
  process.env.NODE_ENV !== "test"
    ? process.env.REACT_APP_BACKEND_URL
    : window.BACKEND_URL !== undefined
    ? window.BACKEND_URL
    : "";
const VERSION =
  process.env.REACT_APP_PREFIX_VERSION !== undefined
    ? process.env.REACT_APP_PREFIX_VERSION
    : window.VERSION !== undefined
    ? window.VERSION
    : "";
const REFRESH_TOKEN_TIME = process.env.REFRESH_TOKEN_TIME || 12 * 60 * 1000;

export default {
  VERSION: VERSION,
  BASENAME: "datahivebuzz",
  SERVICE:
    "datahivebuzz" /* lowercase, must be defined in app d-service-gateway */,

  API_SUFFIX: "/api/4.0/",
  TOKEN_PREFIX: "JWT ",

  PAGE_SIZE: 25,

  HOME_PAGE: `/${VERSION}/`,

  BACKEND_URL: BACKEND_URL,
  BACKEND_ADMIN_SUFFIX: "/admin/",
  BACKEND_TASK_CHANGE_SUFFIX: "/admin/datahivebuzz/task/(pk)/change/",
  MEDIA_SUFFIX: "/media/",

  NON_AUTH_URLS: ["/", "/login", "/logout", "/help"],

  // important constants
  REFRESH_TOKEN_TIME: REFRESH_TOKEN_TIME, // in x[s] * 1000
  // TASKS_EXPIRATION_TIME: 300 * 1000, // in x[s] * 1000
  TASKS_EXPIRATION_TIME: 0,
  REQUEST_REPEAT_DELAY: 30 * 1000,

  DATETIME_FORMAT_LLT: "L LT",
  FORMAT_Ymd: "YYYY-MM-DD",
  FORMAT_dmY_Hm: "DD-MM-YYYY HH:mm",
  FORMAT_ISO_SECOND: "YYYY-MM-DD HH:mm:ssZ",
  FORMAT_Hm: "HH:mm",
  FORMAT_Ymd_Hm: "YYYY-MM-DD HH:mm",
  FORMAT_Dmy_Hm: "DD.MM.YYYY HH:mm",

  SUBMIT_REMINDER_MIN: 30,

  // API Calls
  ADD_MANY_USERS: "add-many-users/",
  ADD_USER: "add-user/",
  ALL_DIVISIONS: "all_divisions/",
  PASSWORD_LOGIN: "password-login/",
  OTP_LOGIN: "key-login/",
  SEND_EMAIL_WITH_OTP: "send-otp-email/",
  SEND_2FA_RESET: "reset-auth/reset_2fa/",
  RESET_2FA: "reset-auth/reset/",
  RESET_2FA_WITH_TOKEN: "reset-auth/token_reset/(token)",
  LOGIN_WITH_TOKEN: "token-login/(token)",
  CONFIRM_WITH_TOKEN: "token-login-mobile-app/(token)", // used by mobile app
  LOGOUT: "token-logout/",
  TOKEN_REFRESH: "token-refresh/",
  UNLOCK_ACCOUNT: "unlock-account/",
  TASK_LIST: "task/",
  TASK_LIST_REVIEWABLE: "task/reviewable/",
  TASK_USERS: "task/(slug)/users_with_answers/",
  TASK_USERS_WITH_ANS_CNTS_FOR_COMPANY:
    "task/(slug)/users_with_answer_counts_from_company/",
  TASK_DETAIL: "task/(pk)/",
  TASK_EXPORT_USERTASKS: "export-usertasks-to-excel/",
  ALL_TASKS_DETAIL: "task/(pk)/?all=1",
  USERS: "users-add-many/",
  USER_PROFILE: "user/profile/",
  USER_DETAILS: "user-details/(username)/",
  USER_DETAILS_USERS: "user-details/users/",
  USER_DETAILS_USERS_UNPAGINATED: "user-details/users_unpaginated/",
  USER_LIST_ALL: "user-details/all/",
  USER_LIST_ALL_USERS: "user-details/all_users/",
  USER_EXPORT_USERS: "export-users-to-excel/",
  USER_EXPORT_PERMISSIONS: "export-permissions-to-excel/",
  USER_TASKS: "user-tasks/(username)/",
  USER_TASKS_WITH_IDS: "user-tasks-with-ids/(user_id)/",
  FRONTEND_USER_PERMS: "user/frontend-perms/",
  LANGUAGES: "languages/",
  COMPANIES: "companies/",
  COMPANY_DETAIL: "companies/(id)/",
  DIVISIONS: "divisions/",
  DIVISION_DETAIL: "divisions/(id)/",
  MODULES: "modules/",
  MODULE_DETAIL: "modules/(id)/",
  USERNAME: "username/(pk)/",
  PROJECTS: "projects/",
  USER_PROFILE_STATUSES: "user-profile-statuses/",
  EDUCATION: "education/",
  LINGUIST_REVIEWS: "user-details/(pk)/reviews/",
  POST_LINGUIST_REVIEWS: "linguist-task-ratings/",
  LINGUIST_REVIEWS_ITEM: "linguist-task-ratings/(pk)/",
  LINGUIST_SEARCH: "linguist-task-ratings/search/",
  MINI_TEST_RESULTS: "mini-test-results/(pk)/",
  PRICE_TARIFFS: "price-tariffs/",
  USERS_PERMISSIONS: "users-permissions/",
  USER_PERMISSIONS_DETAIL: "users-permissions/(id)/",
  COPY_WIKI: "copy-wiki/",
  REORDER_WIKI: "reorder-wiki/(slug)/",
  TICKETS: "tickets/",
  TICKET_DETAIL: "tickets/(id)/",

  // statistics
  TASKS_STATISTICS: "statistics/tasks-statistics/",
  TASKS_STATISTICS_EXPORT: "task-users-statistics-export/",
  TASK_STATISTICS: "statistics/task-statistics/(pk)/",
  GROUP_TASK_STATISTICS: "statistics/task-statistics-group/",
  TASK_STATISTICS_QUARTILES: "statistics/task-statistics-quartiles/(pk)/",
  TASK_STATISTICS_ANSWERS_IN_TIME:
    "statistics/task-statistics-answers-in-time/(pk)/",
  TASK_STATISTICS_ANSWERS_PER_DAY:
    "statistics/task-statistics-answers-per-day/(pk)/",
  TASK_STATISTICS_PERCENTILES: "statistics/task-statistics-percentiles/(pk)/",
  TASK_STATISTICS_FILTERS: "tasks-statistics-filters/",
  TASK_PROBLEMATIC_QUESTIONS: "statistics/task-problematic-questions/(pk)/",
  TASK_USER_BASIC: "statistics/",
  TASK_USER_SESSION: "statistics/session/(pk)/(slug)/",
  TASK_USER_PERCENTILES: "statistics/percentiles/(pk)/(slug)/",
  USERS_STATISTIC: "statistics/users-statistics/",
  USER_STATISTIC: "statistics/user-statistics/(pk)/",
  BASIC_TASKS_STATISTICS: "statistics/basic-tasks-statistics/",
  BASIC_GROUP_TASKS_STATISTICS: "statistics/basic-group-tasks-statistics/",
  BASIC_TASK_STATISTICS: "statistics/basic-task-statistics/(pk)/",
  TASK_IS_ARCHIVED: "statistics/task-is-archived/(pk)/",
  STATISTIC_MANUAL: "statistics/manual/",
  TASK_STATISTICS_USER: "statistics/task-statistics-users-list/(slug)/",

  // upeu special views
  UPEU_APPS: "upeu-apps/",
  UPEU_TASKS: "upeu-tasks/",

  CREATE_ACCOUNT: "create-account/",
  UPDATE_PASSWORD: "update-password/",
  UPDATE_PASSWORD_TOKEN: "update-password-token/",
  PASSWORD_RESET: "reset-auth/reset_password/",
  PASSWORD_RESET_VERIFY_TOKEN: "reset-auth/verify_password_token/",
  PASSWORD_RESET_CONFIRM: "reset-auth/confirm_reset_password/(token)",
  FILE_UPLOAD_FROM_TEXT_EDITOR: "file-upload-from-text-editor/",
  FILE_UPLOAD_WIDGET: "file-upload-widget/",
  FILE_UPLOAD_WIDGET_DELETE: "file-upload-widget-delete/",
  FILE_UPLOAD_WIDGET_UPLOADED: "file-upload-widget-uploaded/",
  AUDIO_ANSWER_WIDGET_REPORT: "audio-answer-widget/",
  VIDEO_WIDGET_REPORT: "video-widget/",
  //ELE
  BADGE_LIST: "badge/",
  BADGE_CREATE: "badge/",
  BADGE_DELETE: "badge/(pk)/",
  BADGE_UPDATE: "badge/(pk)/",
  BADGE_BULK_UPDATE: "badge/bulk-update/",
  LINGUIST_MONTH_REPORT: "linguist-month-report/",
  LINGUIST_MONTH_REPORT_DELETE_RECORD: "linguist-month-report-delete-record/",
  LINGUISTS_MONTH_REPORTS: "linguists-month-reports/",
  LINGUISTS_MONTH_REPORT_HISTORY: "linguist-month-report-history/",
  LINGUISTS_TASK_REPORT_HISTORY: "linguist-task-report-history/",
  LINGUISTS_TASK_MONTH_REPORT_HISTORY: "linguist-task-month-report-history/",
  LINGUISTS_MONTH_REPORTS_TIMES: "linguist-month-report-calculated-times/",
  LINGUISTS_MONTH_REPORTS_DEADLINE: "linguist-month-report-deadline/",
  LINGUIST_EXPORT_REPORTS: "export-report-to-excel/",
  EDIT_LINGUIST_MONTH_REPORT: "edit-linguist-month-report/(pk)/",
  EDIT_LINGUIST_MONTH_REPORT_DEADLINE:
    "edit-linguist-month-report-deadline/(pk)/",
  EDIT_LINGUIST_TASK_REPORT: "edit-linguist-task-report/(pk)/",
  USERS_IN_DIVISION: "users/",

  TEST_FORM: "test-form/",

  // filters options
  PROJECT_OPTIONS: "project-options/",
  MODULE_OPTIONS: "module-options/",
  COMPANY_OPTIONS: "company-options/",
  TASK_FILTER_OPTIONS: "task-filter-options/(taskSlug)/",
  TASK_EVALUATION_FILTER_OPTIONS: "task-evaluation-filter-options/(taskSlug)/",
  TASK_STATUS_OPTIONS: "task-status-options/",
  TASK_USER_DETAILS_FILTER_OPTIONS:
    "task-user-details-filter-options/(taskSlug)/",
  CURRENCY_OPTIONS: "options/tariffs/currencies/",
  TASK_TYPES: "options/task/types/",
  USER_TYPES: "options/user/linguist-types/",
  TICKET_OPTIONS: "ticket-options/",

  // tasks
  TASKS: "tasks/",
  TASK_CONFIGURATION: "task/(pk)/configuration/",
  TASK_RESIGNATION: "task/(pk)/resignation/",
  TASK_PROGRESS_BAR: "task/(taskSlug)/progress-bar/",
  TASK_TUTORIAL_ACCEPT: "task/(taskSlug)/tutorial-accept/",
  TASK_TUTORIAL_DISPLAY_TIME: "task/(taskSlug)/tutorial-display-time/",
  TASK_GET_NEW_QUESTIONS: "task/(taskSlug)/get-new-questions/",
  TASK_ANSWERED_QUESTIONS: "task/(taskSlug)/answered-questions/",
  TASK_GET_NEW_EVALUATIONS: "task/(taskSlug)/get-new-evaluations/",
  TASK_ANSWERED_EVALUATIONS: "task/(taskSlug)/answered-evaluations/",
  TASK_QUESTIONS: "task/(taskSlug)/questions/",
  TASK_SAVE_ANSWER: "task/(taskSlug)/save-answer/(questionId)/",
  TASK_SAVE_EVALUATION: "task/(taskSlug)/save-evaluation/(answerId)/",
  TASK_SAVE_REVIEW: "task/save-review/(answerId)/",
  TASK_QUESTION_SAVE_REVIEW: "task/save-review",
  TASK_ACCEPT_REVIEW: "task/accept-review/(answerId)/",
  TASK_DELETE_COMMENT: "task/delete-comment/(commentId)/",
  TASK_UPDATE_COMMENT: "task/alter-comment/(commentId)/",
  TASK_SAVE_EVALUATION_REVIEW: "task/save-evaluation-review/(evaluationId)/",
  TASK_ACCEPT_EVALUATION_REVIEW: "task/accept-evaluation-review/(answerId)/",
  TASK_DELETE_EVALUATION_COMMENT: "task/delete-evaluation-comment/(commentId)/",
  TASK_UPDATE_EVALUATION_COMMENT: "task/alter-evaluation-comment/(commentId)/",
  TASKS_ALL: "all-tasks/",
  TASKS_UPDATE_FAVORITES:
    "task/(taskSlug)/update-favorites/?pin_color=(taskPinColor)",
  TASK_USERS_DETAILS: "task/(taskSlug)/details/",

  LOGIN_AS: "login-as/",

  // admin
  ADMIN_TASKS: "admin/task/",
  ADMIN_TASKS_DETAIL: "admin/task/(taskSlug)/",
  ADMIN_TASKS_IMPRINT: "admin/tasks-imprint/",
  ADMIN_DUPLICATE_TASKS: "admin/task/duplicate/",
  ADMIN_QUESTIONS: "admin/question/",
  ADMIN_QUESTIONS_DETAIL: "admin/question/(taskSlug)/",
  ADMIN_QUESTIONS_BY_TASK: "admin/task/question/(taskSlug)/questions/",
  ADMIN_QUESTIONS_COUNT_BY_TASK:
    "admin/task/question/(taskSlug)/questions_count/",
  ADMIN_DELETE_ALL_QUESTIONS_BY_TASK:
    "admin/task/question/(taskSlug)/delete_all_questions/",
  ADMIN_ANSWERS: "admin/answer/",
  ADMIN_ANSWER_DETAIL: "admin/answer/(id)/",
  ADMIN_ANSWERS_DETAIL: "admin/answer/(taskSlug)/",
  ADMIN_ANSWERS_BY_TASK: "admin/task/answer/(taskSlug)/answers/",
  ADMIN_ALL_ANSWERS_BY_TASK: "admin/task/answer/(taskSlug)/all_answers/",
  ADMIN_ALL_ANSWERS_COUNT_BY_TASK:
    "admin/task/answer/(taskSlug)/all_answers_count/",
  ADMIN_DELETE_ANSWERS_BY_TASK: "admin/task/answer/(taskSlug)/delete/",
  ADMIN_DELETE_ALL_ANSWERS_BY_TASK:
    "admin/task/answer/(taskSlug)/delete_all_answers/",
  ADMIN_TASKS_DETAIL_RESOLVE:
    "admin/task/(taskSlug)/unresolved_reviews/(users_list)",
  TASK_ACCEPT_ALL_USERS_REVIEWS: "admin/task/(taskSlug)/accept_reviews/",
  ADMIN_GENERATE_RAPORTS: "admin/generate-reports",
  ADMIN_GENERATE_RAPORTS_DETAILS: "admin/generate-reports/(name)",

  /* OTHER OPTIONS */
  OPTION_NOTIFICATION_SHORT_MSG_MAX_LENGTH: 100,

  // audio hunter
  AUDIO_HUNTER_MIGRATE: "audiohunter/migrate/",
  AUDIO_HUNTER_COMPANY_MIGRATE: "audiohunter/migrate-company/",
  AUDIO_HUNTER_RANKING: "audiohunter/all-rankings/",

  // generateReports
  REPORTS_OPTIONS: "reports/types",
  GERERATE_REPORT: "reports/generator",
  REPORTS_LIST: "reports/reports-list",
};

var locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale, { week: { dow: 1 } }); // this sets first day of week in DateInput to Monday
