import _ from "lodash";
import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Switch, useHistory} from "react-router-dom";
import {Provider} from "react-redux";
import qs from "query-string";
import store from "../store/store";
import PrivateRoute from "./PrivateRoute";
import ErrorPage from "../components/ErrorPage";
import {
  PasswordFactorLogin,
  SecondFactorLogin,
  Logout,
  PasswordChange,
  PasswordChangeWithResetToken,
  PasswordReset,
  CreateAccount,
  SecondFactorReset
} from "./Auth";
import Home from "./Home";
import Tasks from "./Tasks";
import Task from "./Task/Task";
import UserProfile from "./UserProfile/UserProfile";
import UserRatings from "./UserProfile/UserRatings";
import TaskRatings from "./TaskRatings/TaskRatings";
import Rating from "./Rating/Rating";
import WidgetsEdit from "./WidgetsEdit/WidgetsEdit";
import UsersList from "./UsersList/UsersList";
import AddManyUsers from "./AddManyUsers/AddManyUsers";
import AddUser from "./AddUser/AddUser";
import TasksStatistics from "./Statistics/TasksStatistics";
import TaskStatistics from "./Statistics/TaskStatistics";
import UsersStatistics from "./Statistics/UsersStatistics";
import TaskStatisticsBasic from "./Statistics/BasicUsersTaskStatistics";
import UserStatistics from "./Statistics/UserStatistics";
import TaskUserStatistics from "./Statistics/TaskUserStatistics";
import UPEUList from "./UPEUList/UPEUList";
import {
  AnswerAdminDetail,
  AnswerAdminListByTask,
  QuestionAdminByTaskList,
  QuestionAdminDetail,
  TaskAdminDetail,
  TaskAdminList
} from "./Admin";
import Ele from "./Ele";
import PriceTariffs from "./PriceTariffs/PriceTariffs";
import {getPerms, refreshTokenImmediately} from "../actions/actions_auth";
import config from "../config/config";
import urls from "../config/frontend_urls";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import ReduxToastr from "react-redux-toastr";
import LinguistReport from "./LinguistReport/LinguistReport";
import LinguistsReportList from "./LinguistReportsList/LinguistsReportList";
import {ConnectedReportDeadlineList} from "./Admin/ReportDeadlines/";
import WidgetsTests from "./WidgetsTests/WidgetsTests";
import ModalAccessToUsersData from "../components/ModalAccessToUsersData";
import {hot} from "react-hot-loader";
import Konami from "./konami";
import {library} from "@fortawesome/fontawesome-svg-core";
import {far} from "@fortawesome/free-regular-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";
import TopNavigationBar from "./TopBar/TopNavigationBar";
import AudioHunterRegistrationSuccess from "./AudioHunterRegistrationSuccess";
import AudioHunter from "./AudioHunter/AudioHunter";
import AudioHunterCompany from "./AudioHunter/AudioHunterCompany";
import {CompanyList, CompanyDetail} from "./Company";
import PermissionsList from "./PermissionsList/PermissionsList";
import {DivisionList, DivisionDetail} from "./Division";
import {ModulesList, ModuleDetail} from "./Modules";
import {GenerateReports} from "./GenerateReports";
import DocsRender from "./DocsRender/DocsRender";
import {TicketDetail, NewTicket, TicketList} from "./Support";

// Add all regular (far) and solid (fas) icons to library so they could have been referred by string names.
library.add(far, fas);

const AppRouter = () => {
  const history = useHistory();
  const [authenticating, setAuthenticating] = useState(true);

  useEffect(() => {
    const url = qs.extract(window.document.URL);
    const callback = () => setAuthenticating(false);
    if (_.get(qs.parse(url), "type") === "backend") {
      store.dispatch(getPerms(callback));
    } else {
      store.dispatch(refreshTokenImmediately(history, callback));
    }
  }, [history]);

  const VERSION = window.VERSION !== undefined ? window.VERSION : `/${config.VERSION}`;

  if (authenticating) {
    return null;
  }

  return (
    <Provider store={store}>
      <BrowserRouter
        basename={window.ROUTER_PREFIX || VERSION}
      >
        <>
          <TopNavigationBar/>
          {Konami()}
          <Switch>
            <Route exact path={urls.HOME} component={Home}/>
            <Route exact path={urls.SECOND_FACTOR_LOGIN} component={SecondFactorLogin}/>
            <Route exact path={urls.PASSWORD_CHANGE} component={PasswordChange}/>
            <Route exact path={urls.SECOND_FACTOR_RESET} component={SecondFactorReset}/>
            <Route exact path={urls.AUDIO_HUNTER_CONFIRM} component={AudioHunterRegistrationSuccess}/>
            <PrivateRoute exact path={urls.LOGIN} component={PasswordFactorLogin}/>
            <PrivateRoute exact path={urls.LOGIN_WITH_TOKEN} component={PasswordFactorLogin}/>
            <PrivateRoute exact path={urls.LOGOUT} component={Logout}/>
            <PrivateRoute exact path={urls.PASSWORD_RESET} component={PasswordReset}/>
            <PrivateRoute exact path={urls.PASSWORD_RESET_CONFIRM}
              component={PasswordChangeWithResetToken}/>
            <PrivateRoute exact path={urls.CREATE_ACCOUNT} component={CreateAccount}/>
            <PrivateRoute exact path={urls.USERS_LIST} component={UsersList}/>
            <PrivateRoute path={urls.PRICE_TARIFFS} component={PriceTariffs}/>
            <PrivateRoute exact path={urls.ADD} component={AddManyUsers}/>
            <PrivateRoute exact path={urls.ADD_USERS} component={AddManyUsers}/>
            <PrivateRoute exact path={urls.ADD_USER} component={AddUser}/>
            <PrivateRoute exact path={urls.COMPANIES} component={CompanyList}/>
            <PrivateRoute exact path={urls.COMPANY_DETAIL} component={CompanyDetail}/>
            <PrivateRoute exact path={urls.COMPANY_DETAIL_NEW} component={CompanyDetail}/>
            <PrivateRoute exact path={urls.USERS_PERMISSIONS} component={PermissionsList}/>
            <PrivateRoute exact path={urls.DIVISIONS} component={DivisionList}/>
            <PrivateRoute exact path={urls.DIVISION_DETAIL} component={DivisionDetail}/>
            <PrivateRoute exact path={urls.DIVISION_DETAIL_NEW} component={DivisionDetail}/>
            <PrivateRoute exact path={urls.MODULES} component={ModulesList}/>
            <PrivateRoute exact path={urls.MODULE_DETAIL} component={ModuleDetail}/>
            <PrivateRoute exact path={urls.MODULE_DETAIL_NEW} component={ModuleDetail}/>
            <PrivateRoute exact path={urls.USER_PROFILE} component={UserProfile}/>
            <PrivateRoute exact path={urls.USER_RATINGS} component={UserRatings}/>
            <PrivateRoute exact path={urls.TASKS_LIST} component={Tasks}/>
            <PrivateRoute exact path={urls.TASK_RATINGS} component={TaskRatings}/>
            <PrivateRoute exact path={urls.RATING} component={Rating}/>
            <PrivateRoute exact path={urls.NEW_RATING} component={Rating}/>
            <PrivateRoute exact path={urls.NEW_RATING_NO_SLUG} component={Rating}/>
            <PrivateRoute exact path={urls.TASK} component={Task}/>
            <PrivateRoute exact path={urls.TASK_TAB} component={Task}/>
            <PrivateRoute exact path={urls.TASKS_STATISTICS} component={TasksStatistics}/>
            <PrivateRoute exact path={urls.BASIC_TASK_STATISTICS} component={TaskStatisticsBasic}/>
            <PrivateRoute exact path={urls.TASK_STATISTICS} component={TaskStatistics}/>
            <PrivateRoute exact path={urls.GROUP_TASK_STATISTICS} component={TaskStatistics}/>
            <PrivateRoute exact path={urls.USERS_STATISTICS} component={UsersStatistics}/>
            <PrivateRoute exact path={urls.USER_STATISTICS} component={UserStatistics}/>
            <PrivateRoute exact path={urls.TASK_USER_STATISTICS}
              component={TaskUserStatistics}/>
            <PrivateRoute exact path={urls.REPORTS} component={Ele}/>
            <PrivateRoute exact path={urls.USER_REPORT} component={LinguistReport}/>
            <PrivateRoute exact path={urls.REPORTS_LIST} component={LinguistsReportList}/>
            <PrivateRoute exact path={urls.REPORTS_DEADLINE} component={ConnectedReportDeadlineList} />
            <PrivateRoute exact path={urls.WIDGETS_EDIT} component={WidgetsEdit}/>
            <PrivateRoute exact path={urls.UPEU} component={UPEUList} key={_.uniqueId()}/>
            <PrivateRoute exact path={urls.WIDGETS_TESTS} component={WidgetsTests}/>
            <PrivateRoute exact path={urls.ADMIN_TASK_LIST} component={TaskAdminList}/>
            <PrivateRoute exact path={urls.ADMIN_TASK_DETAIL_NEW} component={TaskAdminDetail}/>
            <PrivateRoute exact path={urls.ADMIN_TASK_DETAIL_NEW_TAB} component={TaskAdminDetail}/>
            <PrivateRoute exact path={urls.ADMIN_TASK_DETAIL} component={TaskAdminDetail}/>
            <PrivateRoute exact path={urls.ADMIN_TASK_DETAIL_TAB} component={TaskAdminDetail}/>
            <PrivateRoute exact path={urls.AUDIO_HUNTER_MIGRATE} component={AudioHunter}/>
            <PrivateRoute exact path={urls.AUDIO_HUNTER_COMPANY_MIGRATE} component={AudioHunterCompany}/>
            <PrivateRoute exact path={urls.ADMIN_QUESTIONS_DETAIL}
              component={QuestionAdminDetail}/>
            <PrivateRoute exact path={urls.ADMIN_QUESTIONS_BY_TASK_LIST}
              component={QuestionAdminByTaskList}/>
            <PrivateRoute exact path={urls.ADMIN_ANSWERS_BY_TASK_LIST}
              component={AnswerAdminListByTask}/>
            <PrivateRoute exact path={urls.ADMIN_ANSWER_DETAIL}
              component={AnswerAdminDetail}/>
            <PrivateRoute exact path={urls.ADMIN_GENERATE_REPORTS} component={GenerateReports}/>
            <PrivateRoute path={urls.DOCUMENTATION_WIDGETS} component={DocsRender}/>
            <PrivateRoute exact path={urls.SUPPORT} component={TicketList}/>
            <PrivateRoute exact path={urls.SUPPORT_TICKET_NEW} component={NewTicket} />
            <PrivateRoute exact path={urls.SUPPORT_TICKET_DETAIL} component={TicketDetail} />
            <Route><ErrorPage errorCode={404}/></Route>
          </Switch>
        </>
      </BrowserRouter>
      <ModalAccessToUsersData/>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar/>
    </Provider>
  );
};

/* eslint-disable no-undef */
export default process.env.NODE_ENV === "development"
  ? hot(module)(AppRouter)
  : AppRouter;
/* eslint-enable no-undef */
